<template>
<base-popup
    :popup-model="show"
    title="Emails Details"
    :valid="!valid"
    :width="80"
    :show-save-button="false"
    @onCancelButtonClick="closeEmailPopupHandler"
>
  <v-timeline style="overflow-x: hidden">
    <v-timeline-item
        v-for="(item, key) in items"
        :key="key"
        color="red lighten-2"
        large
    >
      <template v-slot:opposite>
        <span>{{getDate(item.created_at)}}</span>
      </template>
      <v-card class="elevation-2">
        <v-card-title class="text-h5">
          {{item.subject}}
        </v-card-title>
        <v-card-text v-html="item.body"></v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
  <div
      v-if="!isLastPage"
      v-observe-visibility="{callback: visibilityChanged}"
      class="d-flex justify-center"
  >
    <v-progress-circular indeterminate color="primary"/>
  </div>
</base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import Popup from "../mixins/Popup";
import Api from "../../services/Api";
export default {
  name: "EmailDetailsPopup",
  data() {
    return {
      isLastPage: false,
      isShowLoading: true,
      selected: [],
      items: [],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        descending: true,
        sortBy: 'created_at'
      },
      currentPage: 0,
      apiPath: 'get-emails-details-by-email'
    }
  },
  components: {
    BasePopup
  },
  mixins: [Popup],
  watch: {
    currentItem: {
      handler: function (item) {
        if(item && item.email) {
          this.items = [];
          this.getEmailDetails();
        }
      },
      immediate: true
    }
  },
  methods: {
    visibilityChanged(e) {
      if(e) {
        this.getEmailDetails(true);
      }
    },
    getEmailDetails(newPage = false) {
      if (newPage) {
        ++this.currentPage;
      } else {
        this.currentPage = 1;
      }

      this.pagination.page = this.currentPage;
      let invoiceId = this.currentItem.invoiceId ? this.currentItem.invoiceId : '';
debugger
      const path = `/${this.apiPath}?rowsPerPage=10&descending=${this.pagination.descending}&page=${this.currentPage}&sortBy=${this.pagination.sortBy}&email=${this.currentItem.email}&invoiceId=${invoiceId}`;
      Api.getRequest({endpoint: path})
          .then((response) => {
            this.items = this.items.concat(response.data.data);
            this.isLastPage = this.items.length === response.data.total;
            this.isShowLoading = false;
          })
    },
    closeEmailPopupHandler() {
      this.$emit('closePopupHandler', true);
    }
  }
}
</script>

<style scoped>

</style>